import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { Layout } from '@components';
import { usePrefersReducedMotion } from '@hooks';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import 'katex/dist/katex.min.css';

const StyledPostContainer = styled.div`
  @media (max-width: 768px) {
    margin: 50px -10px;
  }

  h1 {
    margin-bottom: 20px;
  }

  .blog-cover {
    margin-bottom: 20px;
    width: 100%;
    height: 300px;
    object-fit: contain;
  }

  .katex {
    font-size: 0.85em;
  }
`;

const BlogPostTemplate = ({ location, data }) => {
  const { defaultTitle, siteUrl } = data.site.siteMetadata;

  const post = data.markdownRemark;
  const objectFit = post.frontmatter.objectFit || 'cover';
  const revealTitle = useRef(null);
  const revealPosts = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    revealPosts.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const image = getImage(post.frontmatter.coverImage);
  const imageUrl = `${siteUrl}${image.images.fallback.src}`;

  return (
    <Layout location={location}>
      <Helmet title={`${post.frontmatter.title}`}>
        <meta name="description" content={post.frontmatter.description} />
        <meta name="image" content={imageUrl} />

        <meta property="og:site_name" content={`${defaultTitle} | Blog`} />
        <meta property="og:title" content={`${post.frontmatter.title} | ${defaultTitle}`} />
        <meta property="og:description" content={post.frontmatter.description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={location.href} />
        <meta property="og:type" content="article" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.frontmatter.title} />
        <meta name="twitter:description" content={post.frontmatter.description} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      <main>
        <StyledPostContainer>
          <div>
            <GatsbyImage
              image={image}
              alt={post.frontmatter.title}
              className="blog-cover"
              objectFit={objectFit}
            />
            <h1>{post.frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            {/* You can also display the post date, author, etc. here */}
          </div>
        </StyledPostContainer>
      </main>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default BlogPostTemplate;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        description
        tags
        coverImage {
          childImageSharp {
            gatsbyImageData(width: 2000, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        objectFit
      }
    }
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`;
